// All Variables
// --------------------------------------------------
$colors: ( lightBlue: #0058a2, blue: #055499, darkblue: #1b5795, darkerblue: #074173, orange: #f08300, lightorange:#f39810, lightgrey: #f7f7f7, grey:#eeeeee, darkgrey: #333333, extragrey: #1a1a1a, dark: #222, white: #fff, danger: #a94442, success: #3c763d, green: #88c43e);
//Font-size
$fontSize_normal: 16px;
$fontSize_small: 14px;
$fontSize_smallest: 11px;
$fontSize_smaller: 16px;
$fontSize_bigger: 18px;
$fontSize_big: 20px;
$fontSize_biggest: 22px;
$fontSize_title_big: 24px;
$fontSize_title_bigger: 26px;
$fontSize_title_biggest: 28px;
$fontSize_title_main: 32px;
// Elements
// --------------------------------------------------
h1,
h2,
h3,
h4,
h5 {}

.b {
      font-weight: bold !important;
      &-light {
            font-weight: 500 !important;
      }
}

.text-uppercase {
      text-transform: uppercase;
}

.flex-box {
      display: flex;
      align-items: center;
}

p {
      &.normal {}
      &.big {}
      &.small {}
      &.extrasmall {}
}

a {
      transition: all .5s;
      &:hover,
      &:focus,
      &:active,
      &.active {
            text-decoration: none;
      }
      &.dark-link {
            color: map-get($colors, white);
            &:hover,
            &:focus,
            &:active,
            &.active {
                  color: map-get($colors, orange);
            }
      }
      &.light-link {
            color: map-get($colors, dark);
            &:hover,
            &:focus,
            &:active,
            &.active {
                  color: map-get($colors, blue);
            }
      }
      &.no-style {
            color: map-get($colors, dark);
            &:hover,
            &:focus,
            &:active,
            &.active {
                  color: map-get($colors, dark);
            }
      }
      &.underline {
            text-decoration: underline;
      }
}

.title {
      &-double {
            text-transform: uppercase;
            span {
                  display: block;
                  margin-top: 7px;
                  font-weight: 300;
                  &:first-of-type {
                        margin-top: 0;
                  }
                  &.white {
                        color: map-get($colors, white);
                  }
                  &.blue {
                        color: map-get($colors, blue);
                  }
                  &.orange {
                        color: map-get($colors, orange);
                  }
                  &.main-title {
                        font-size: $fontSize_title_biggest;
                        font-weight: 700;
                  }
                  &.sub-title {
                        font-size: $fontSize_title_bigger;
                        font-weight: 600;
                  }
                  &.bottom-line {
                        width: 40px;
                        height: 2px;
                        margin: 15px 0;
                        &.dark-line {
                              background-color: map-get($colors, darkgrey);
                        }
                        &.light-line {
                              background-color: map-get($colors, white);
                        }
                        &.line-right {
                              margin-left: auto;
                        }
                  }
            }
      }
}

.img-list {
      display: inline-block;
      margin: 0;
      &>li {
            margin: 0 10px;
      }
      .box-content {
            width: 150px;
            margin-top: 15px;
            margin-bottom: 15px;
            display: inline-table;
            .img-box {
                  display: table-cell;
                  width: 150px;
                  height: 150px;
                  vertical-align: middle;
                  text-align: center;
                  .back-circle {
                        background: map-get($colors, lightgrey);
                        border-radius: 50%;
                        transition: all 0.5s;
                        display: inline-block;
                        width: 150px;
                        height: 150px;
                        position: relative;
                        background-repeat: no-repeat;
                        background-position: center;
                        &.espiritu-servicio {
                              background-image: url(../img/ico_espiritu.png);
                        }
                        &.compromiso {
                              background-image: url(../img/ico_compromiso.png);
                        }
                        &.lealtad {
                              background-image: url(../img/ico_lealtad.png);
                        }
                        &.honestidad-honradez {
                              background-image: url(../img/ico_honestidad.png);
                        }
                        &.disiplina {
                              background-image: url(../img/ico_disciplina.png);
                        }
                        &.ser-hacedor {
                              background-image: url(../img/ico_hacedor.png);
                        }
                        &.facil {
                              background-image: url(../img/ico_facil.png);
                        }
                        &.rapido {
                              background-image: url(../img/ico_rapido.png);
                        }
                        &.servicio {
                              background-image: url(../img/ico_servicio.png);
                        }
                        &.variedad {
                              background-image: url(../img/ico_variedad.png);
                        }
                  }
            }
            .text-box {
                  text-transform: uppercase;
                  height: 40px;
                  font-weight: 600;
                  transition: all 0.5s;
                  margin-top: 15px;
            }
            &:hover {
                  .img-box {
                        .back-circle {
                              box-shadow: 3px 3px 30px -10px rgba(0, 0, 0, .5);
                        }
                  }
                  .text-box {
                        font-weight: 700;
                        color: map-get($colors, blue);
                  }
            }
      }
      &.spaced>li {
            margin: 0 20px;
      }
}

.custom-list {
      list-style: none;
      padding-left: 20px;
      position: relative;
      li {
            line-height: 30px;
            text-transform: lowercase;
            &::first-letter {
                  text-transform: uppercase;
            }
            &:before {
                  content: "\f00c";
                  font-family: FontAwesome;
                  position: absolute;
                  color: map-get($colors, orange);
                  left: 0;
            }
      }
}

.error-empty {
      text-align: center;
      padding: 100px 30px;
      color: map-get($colors, danger);
      height: 300px;
}

.alert-label {
      border: 1px solid;
      text-align: center;
      padding: 8px 5px 5px;
      margin-top: 25px;
      text-transform: uppercase;
      letter-spacing: 2px;
}

.success-alert {
      color: map-get($colors, success);
      border-color: map-get($colors, success);
      background: rgba(60, 118, 61, 0.1);
}

.danger-alert {
      color: map-get($colors, danger);
      border-color: map-get($colors, danger);
      background: rgba(169, 68, 66, 0.1);
}

.vcenter {
      display: inline-block;
      vertical-align: middle;
      float: none;
}

.color {
      &-orange {
            color: map-get($colors, orange);
      }
      &-blue {
            color: map-get($colors, orabluenge);
      }
}

// Img background
// --------------------------------------------------
.back-img {
      background-repeat: no-repeat;
      padding: 30px 0;
      &.car-key {
            background-size: auto 100%;
            background-position-x: 100%;
            color: map-get($colors, white);
            background-image: url(../img/vision.jpg);
      }
      &.blue-card {
            position: relative;
            padding: 30px 30px 45px;
            background-size: 102% 102%;
            position: relative;
            background-color: map-get($colors, white);
            color: map-get($colors, white);
            background-image: url(../img/bg_precio_preferencial.png);
            &.smaller {
                  padding: 25px 30px;
            }
      }
      &.blue-car {
            padding: 0;
            background-color: map-get($colors, blue);
            .back {
                  background-image: url(../img/ventoslider.png);
                  background-position: 0 calc(100% - 56px);
                  background-size: auto 52%;
                  background-repeat: no-repeat;
            }
      }
      @media (min-width: 1180px) {
            &.car-key {
                  background-size: 100%;
            }
      }
}

// Spaces margin && padding
// --------------------------------------------------
.margin {

      margin: 20px !important;
      &-top {
        margin-top: 20px !important;
        @media screen and (max-width: 768px) {
            margin-top: 80px !important;
          }
      }

      &-iniciocontent{
        @media screen and (max-width: 375px) {
            margin-top: 120px !important;
          }
      }

      &-bottom {
            margin-bottom: 20px !important;
      }
}

.no-margin {
      margin: 0 !important;
      &-top {
            margin-top: 0 !important;
      }
      &-bottom {
            margin-bottom: 0 !important;
      }
}

.padding {
      padding: 20px !important;
      &-top {
            padding-top: 20px !important;
      }
      &-bottom {
            padding-bottom: 20px !important;
      }
      &-right {
            padding-right: 20px !important;
      }
      &-left {
            padding-left: 20px !important;
      }
}

// Check box
// --------------------------------------------------
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
}

[type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
      position: relative;
      padding-left: 1.95em;
      cursor: pointer;
}

[type="checkbox"]:not(:checked)+label:before,
[type="checkbox"]:checked+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25em;
      height: 1.25em;
      border: 2px solid #ccc;
      background: #fff;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
}

[type="checkbox"]:not(:checked)+label:after,
[type="checkbox"]:checked+label:after {
      content: '✔';
      position: absolute;
      top: .2em;
      left: .2em;
      font-size: 1.1em;
      line-height: 0.8;
      color: map-get($colors, orange);
      transition: all .2s;
}


/* checked mark aspect changes */

[type="checkbox"]:not(:checked)+label:after {
      opacity: 0;
      transform: scale(0);
}

[type="checkbox"]:checked+label:after {
      opacity: 1;
      transform: scale(1);
}

[type="checkbox"]:disabled:not(:checked)+label:before,
[type="checkbox"]:disabled:checked+label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
}

[type="checkbox"]:disabled:checked+label:after {
      color: #999;
}

[type="checkbox"]:disabled+label {
      color: #aaa;
}

label:hover:before {
      border: 2px solid map-get($colors, orange) !important;
}

// CSS
// --------------------------------------------------
body {
      font-family: 'Poppins', sans-serif;
      overflow-x: hidden;
      font-size: $fontSize_normal;
}

img {
      max-width: 100%;
      &.img-centered {
            margin: 0 auto;
            display: block;
      }
}

header {
      text-align: center;
      background: map-get($colors, white);
      color: map-get($colors, dark);
      .container {
            padding-top: 100px;
            padding-bottom: 50px;
      }
      img {
            display: block;
            margin: 0 auto 20px;
      }
      .intro-text {
            .name {
                  display: block;
                  //font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 2em;
            }
            .skills {
                  font-size: 1.25em;
                  font-weight: 300;
            }
      }
}

.navbar-custom {
      //font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
      //text-transform: uppercase;
      //box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.1);
      font-weight: 400;
      border: none;
      background: map-get($colors, white);
      .navbar-brand {
            color: map-get($colors, dark);
            &:hover,
            &:focus,
            &:active,
            &.active {
                  text-shadow: 0 0 0 black, 0 0 0 black, 0 0 0 black;
            }
      }
      .box-phones {
            padding-top: 15px;
            .content {
                  padding-left: 18px;
                  font-size: $fontSize_small;
                  border-bottom: 2px solid #eee;
                  display: inline-block;
                  position: relative;
                  b {
                        margin-left: 5px;
                  }
                  i {
                        font-size: $fontSize_bigger;
                        color: map-get($colors, blue);
                        position: absolute;
                        top: 6px;
                        left: 2px;
                  }
                  .btn {
                        font-size: $fontSize_bigger;
                        margin-left: 5px;
                        border-radius: 0;
                        background: map-get($colors, lightgrey);
                        color: map-get($colors, blue);
                        font-weight: 500;
                        padding-bottom: 2px;
                        padding-top: 5px;
                        margin-top: -3px;
                        border-color: transparent;
                        transition: all .5s;
                        &:hover,
                        &:focus,
                        &.active {
                              color: map-get($colors, lightgrey);
                              background: map-get($colors, blue);
                        }
                  }
            }
      }
      img {
            height: 70px;
            margin: 10px 0;
            transition: all .5s;
      }
      .navbar-nav {
            letter-spacing: 1px;
            li {
                  a {
                        font-weight: 400;
                        transition: all .5s;
                        color: map-get($colors, darkgrey);
                        padding-bottom: 12px;
                        &:hover,
                        &:focus,
                        &:active {
                              text-shadow: 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey);
                              color: map-get($colors, darkgrey);
                              background-color: transparent;
                        }
                  }
                  &.active a {
                        text-shadow: 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey);
                        color: map-get($colors, dark);
                        background-color: transparent;
                        &:hover,
                        &:focus,
                        &:active {
                              text-shadow: 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey), 0 0 0 map-get($colors, darkgrey);
                              color: map-get($colors, dark);
                              background-color: transparent;
                        }
                  }
            }
      }
      .navbar-toggle {
            color: white;
            text-transform: uppercase;
            font-size: $fontSize_small;
            border-color: white;
            &:hover,
            &:focus {
                  background-color: #18BC9C;
                  color: white;
                  border-color: #18BC9C;
            }
      }
}

.nav-tabs {
      text-align: center;
      border-bottom: 2px solid map-get($colors, grey) !important;
      &>li {
            float: none;
            display: inline-block;
            &>a {
                  color: map-get($colors, dark);
                  padding: 10px 45px;
                  font-weight: 400;
                  border-left: 1px solid transparent !important;
                  border-right: 1px solid transparent !important;
                  border-bottom: 2px solid transparent;
                  font-size: $fontSize_bigger;
                  &:hover,
                  &:focus {
                        border: transparent;
                        color: map-get($colors, orange);
                        background: transparent;
                        border-left: 1px solid transparent !important;
                        border-bottom: 2px solid transparent !important;
                        border-right: 1px solid transparent !important;
                  }
                  &:focus {
                        border-bottom: 2px solid map-get($colors, orange) !important;
                  }
            }
            &.active {
                  &>a {
                        border: transparent;
                        border-bottom: 2px solid map-get($colors, orange);
                        color: map-get($colors, orange);
                        &:hover,
                        &:focus {
                              border: transparent;
                              color: map-get($colors, orange);
                              background: transparent;
                              border-bottom: 2px solid map-get($colors, orange) !important;
                              border-left: 1px solid transparent !important;
                              border-right: 1px solid transparent !important;
                        }
                  }
            }
      }
}

.top-margin {
      margin-top: 90px !important;
      &.padding-top {
            padding-top: 20px !important;
      }
}

.small-margin {
      margin-top: 10px !important;
}

section.section {
      margin: 50px 0;
}

.section {
      &.section-content {
            padding: 20px 20px 10px;
            margin: 20px 0;
      }
}

.bak-grey {
      background-color: map-get($colors, lightgrey);
      border-bottom: 2px solid map-get($colors, grey);
      &.bak-contener {
            border-bottom: 0;
            margin-bottom: 0;
            padding-top: 30px;
            padding-bottom: 50px;
      }
      &.no-border {
            border-bottom: 0;
      }
      &.border-all {
            border: 2px solid map-get($colors, grey);
      }
}

.bak-blue {
      background-color: map-get($colors, blue);
      color: map-get($colors, white);
}

.bak-shadow {
      background-color: rgba(0, 0, 0, 0.25);
}

// Footer
// --------------------------------------------------
footer {
      color: map-get($colors, white);
      background-color: map-get($colors, darkgrey);
      h4 {
            margin-bottom: 10px;
            text-transform: uppercase;
      }
      .footer-above {
            padding-top: 50px;
      }
      .footer-col {
            font-size: $fontSize_small;
            text-align: left;
            margin-bottom: 30px;
            img {
                  height: 110px;
                  margin: 20px 0;
            }
            ul {
                  list-style-type: none;
                  li {
                        padding-left: 22px;
                        position: relative;
                        line-height: 30px;
                        &.custom-icon:before {
                              font-family: FontAwesome;
                              color: map-get($colors, orange);
                              position: absolute;
                              left: 0;
                        }
                        &.phone:before {
                              content: "\f095";
                              font-size: $fontSize_bigger;
                        }
                        &.email:before {
                              content: "\f003";
                              margin-top: -1px;
                        }
                        &.location:before {
                              content: "\f041";
                              font-size: $fontSize_bigger;
                              margin-left: 3px;
                              margin-top: -2px;
                        }
                  }
            }
      }
      .footer-below {
            padding: 35px 0;
            background-color: map-get($colors, extragrey);
      }
      .label-about-us {
          @media screen and (max-width: 425px) {
            padding: 0px;
          }
            background-color: map-get($colors, blue);
            padding: 12px 10px;
            display: inline-block;
            width: 65%;
            //min-width: 300px;
            font-weight: 100;
            font-size: $fontSize_big;
            color: map-get($colors, white);
            position: relative;
            margin: 0 55px;
            .b-light {
                  position: relative;
                  font-size: $fontSize_biggest;
                  padding-right: 25px;
                  &.icon-next:after {
                        transition: all 0.5s;
                        content: "\f105";
                        font-family: FontAwesome;
                        position: absolute;
                        top: -3px;
                        right: 5px;
                        font-size: 28px;
                  }
            }
            &:before {
                  content: '';
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 55px 55px 0;
                  border-color: transparent map-get($colors, blue) transparent transparent;
                  position: absolute;
                  top: 0;
                  transition: all 0.5s;
                  left: -55px;
            }
            &:after {
                  content: '';
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 55px 55px 0 0;
                  border-color: map-get($colors, blue) transparent transparent transparent;
                  position: absolute;
                  top: 0;
                  transition: all 0.5s;
                  right: -55px;
            }
            &:hover {
                  color: map-get($colors, white);
                  background-color: map-get($colors, darkerblue);
                  .b-light {
                        &.icon-next:after {
                              right: 0;
                        }
                  }
                  &:before {
                        border-color: transparent map-get($colors, darkerblue) transparent transparent;
                  }
                  &:after {
                        border-color: map-get($colors, darkerblue) transparent transparent transparent;
                  }
            }
      }
}

// Product BOX
// --------------------------------------------------
.product-box {
      width: 250px;
      border: 1px solid map-get($colors, lightgrey);
      margin: 20px auto;
      transition: all 0.5s;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      position: relative;
      .product-img {
            width: 100%;
            max-width: 100%;
            height: 190px;
            img {
                  margin: 0 auto;
                  display: block;
            }
      }
      .product-info {
            padding: 5px 0;
            .product {
                  &-name {
                        padding: 0 10px;
                        margin-top: 0;
                        text-align: center;
                        span {
                              display: block;
                              font-weight: 400;
                              &.main-title {
                                    font-weight: 700;
                              }
                        }
                  }
                  &-price {
                        font-size: $fontSize_title_big;
                        display: flex;
                        align-items: right;
                        background-color: map-get($colors, orange);
                        margin-bottom: 5px;
                        .price {
                              padding: 4px 0 0 0;
                              color: map-get($colors, white);
                              font-weight: 600;
                              margin-left: auto;
                              margin-right: 0;
                              transition: all 0.5s;
                        }
                        .day {
                              position: relative;
                              color: map-get($colors, orange);
                              background-color: map-get($colors, white);
                              padding: 2px 10px 2px 3px;
                              margin-left: 15px;
                              &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0px 0 38px 15px;
                                    border-color: transparent transparent map-get($colors, white) transparent;
                                    position: absolute;
                                    top: 0;
                                    left: -15px;
                              }
                        }
                  }
                  &-payment {
                        padding: 3px 10px;
                        text-align: center;
                        .text {
                              text-transform: uppercase;
                        }
                        .price {
                              font-weight: 600;
                              font-size: $fontSize_biggest;
                        }
                  }
            }
      }
      &:before {
            position: absolute;
            z-index: -1;
            content: '';
            top: calc(100% + 0px);
            left: 5%;
            height: 10px;
            width: 80%;
            background: radial-gradient(ellipse at center, rgba(0, 0, 0, .35) 0, transparent 80%);
            transition: all 0.5s;
            opacity: 1;
      }
      &:hover {
            border-color: map-get($colors, orange);
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(240, 131, 0, 0.7);
            &:before {
                  opacity: 0;
            }
            .product-info {
                  .product {
                        &-price {
                              .price {
                                    margin-right: 5px;
                              }
                        }
                  }
            }
      }
}

// Buttons - style
// --------------------------------------------------
.btn {
      font-size: $fontSize_big;
      padding: 12px 30px 8px;
      transition: all 0.5s;
      text-transform: uppercase;
      border-radius: 0;
      font-weight: 600;
      &.capitalize {
            text-transform: capitalize;
      }
      &-outline {
            background-color: map-get($colors, white);
            color: map-get($colors, dark);
            &:before {
                  content: "";
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  top: 55%;
                  left: 0;
                  background-color: map-get($colors, grey);
                  z-index: -1;
            }
            &.orange {
                  border: 2px solid map-get($colors, orange);
                  &:hover {
                        background-color: map-get($colors, orange);
                        color: map-get($colors, white);
                  }
            }
            &.blue {
                  border: 2px solid map-get($colors, blue);
                  &:hover {
                        background-color: map-get($colors, blue);
                        color: map-get($colors, white);
                  }
            }
      }
      &-full {
            &.orange {
                  border: 2px solid map-get($colors, orange);
                  background: map-get($colors, orange);
                  color: map-get($colors, white);
                  &:hover {
                        border: 2px solid map-get($colors, lightorange);
                        background: map-get($colors, lightorange);
                  }
                  &.smaller {
                        padding: 3px 46px 0px;
                        &:after {}
                  }
            }
            &.default {
                  background: #e3e3e3;
                  color: map-get($colors, dark);
                  transition: all 0.5s;
                  .fa {
                        font-size: 36px;
                  }
                  &:hover {
                        background: #dcdcdc;
                        //color: map-get($colors, white);
                  }
                  &.smaller {
                        padding: 1px 15px 0px;
                        &:after {}
                  }
            }
            &.blue {
                  border: 2px solid map-get($colors, blue);
                  background: map-get($colors, blue);
                  color: map-get($colors, white);
                  &:hover {
                        border: 2px solid map-get($colors, lightBlue);
                        background: map-get($colors, lightBlue);
                  }
                  &.smaller {
                        padding: 3px 46px 0px;
                        &:after {}
                  }
            }
      }
      &-over {
            margin-top: -20px;
            z-index: 1;
            position: absolute;
            width: 100%;
            bottom: -25px;
            left: 0;
      }
      &-circle {
            border-radius: 50%;
            &.grey {
                  background-color: map-get($colors, grey);
            }
      }
      .b-light {
            position: relative;
            font-size: 24px;
            padding-right: 25px;
            &.icon-next:after {
                  transition: all 0.5s;
                  content: "\f105";
                  font-family: FontAwesome;
                  position: absolute;
                  top: -3px;
                  right: 5px;
                  font-size: 28px;
            }
      }
      &:hover {
            .b-light {
                  &.icon-next:after {
                        right: 0;
                  }
            }
      }
      &.block {
            width: 100%;
      }
}

// Box - shadows
// --------------------------------------------------
.shadow-tirangule {
      &:before,
      &:after {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 15px;
            left: 10px;
            width: 80%;
            top: 70%;
            height: 30px;
            max-width: 500px;
            background: #777;
            box-shadow: 0 26px 10px #777;
            transform: rotate(-3deg);
      }
      &:after {
            transform: rotate(3deg);
            right: 10px;
            left: auto;
      }
}

// Forms - boxes
// --------------------------------------------------
.box-filter {
      margin: 0 15px;
      @media screen and (max-width: 768px) {
        margin-top: 70px;
      }
      .title-double {
            padding-bottom: 10px;
            border-bottom: 3px solid map-get($colors, orange);
            margin-bottom: 10px;
            margin-top: 20px;
            .main-title {
                  font-size: $fontSize_title_main;
            }
      }
      .content {
            margin: 0 20px;
            .form {
                  padding: 20px 15px 0;
                  background-color: map-get($colors, white);
                  label {
                        text-transform: uppercase;
                  }
                  .form-control {
                        background: map-get($colors, grey);
                        border-color: map-get($colors, grey);
                        border-radius: 0;
                        padding: 5px 12px;
                        font-size: 16px;
                        &:focus {
                              border-color: map-get($colors, orange);
                              -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                              box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
                        }
                  }
                  .select2-selection {
                        background: map-get($colors, grey);
                        border-color: #e8e8e8;
                        border-radius: 0;
                        padding: 5px 12px;
                        height: 34px;
                        &:focus {
                              border-color: map-get($colors, orange) !important;
                              -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                              box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
                        }
                  }
                  .select2-container *:focus {
                        outline: none;
                  }
                  .select2-container .select2-selection--single .select2-selection__rendered {
                        padding-left: 0;
                  }
                  .select2-selection__arrow {
                        display: none;
                  }
                  .select2-selection {
                        &:after {
                              content: "\f107";
                              font-size: 24px;
                              font-weight: bold;
                        }
                  }
                  .select2-selection__clear {
                        margin-right: 5px;
                  }
                  .input-icon,
                  .select2-selection {
                        position: relative;
                        &.select {
                              select {
                                    padding-right: 30px;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                              }
                              &:after {
                                    content: "\f107";
                                    font-size: 24px;
                                    font-weight: bold;
                              }
                        }
                        &.calendar {
                              input {
                                    padding-right: 30px;
                              }
                              &:after {
                                    content: "\f073";
                                    font-size: 18px;
                                    font-weight: 500;
                                    top: 5px;
                              }
                        }
                        &:after {
                              font-family: FontAwesome;
                              color: map-get($colors, orange);
                              position: absolute;
                              right: 0;
                              top: 0;
                              padding: 0 7px;
                        }
                        &:before {
                              content: "";
                              background: map-get($colors, lightgrey);
                              border-left: 2px solid map-get($colors, grey);
                              position: absolute;
                              right: 1px;
                              top: 1px;
                              height: calc(100% - 2px);
                              width: 32px;
                        }
                  }
            }
      }
      .button {
            button {
                  width: 100%;
                  font-size: $fontSize_title_big;
                  i {
                        margin-left: 10px;
                  }
            }
      }
}

.list-filter {
      &.title {
            padding-top: 20px;
            padding-left: 15px;
            text-transform: uppercase;
            position: relative;
            font-weight: 600;
            &:before {
                  content: "\f105";
                  font-family: FontAwesome;
                  color: map-get($colors, orange);
                  position: absolute;
                  left: 0px;
                  font-size: 20px;
                  top: 15px;
            }
      }
      &.list-content {
            margin-top: 5px;
            padding-left: 15px;
            li {
                  line-height: 35px;
                  transition: all 0.5s;
                  a {
                        transition: all 0.5s;
                  }
            }
            &.category {
                  li {
                        padding-top: 10px;
                        &.active {
                              a {
                                    border-bottom: 4px solid map-get($colors, orange);
                                    padding: 0 5px;
                              }
                        }
                        a {
                              color: map-get($colors, dark);
                              border-bottom: 4px solid transparent;
                              &:hover {
                                    color: map-get($colors, orange);
                              }
                        }
                  }
            }
            &.tags {
                  li {
                        //margin: 3px 0;
                        a {
                              color: #656565;
                              text-transform: uppercase;
                              border-bottom: 1px solid #e4e4e4;
                              //background-color: #f9f9f9;
                              padding: 8px 5px 5px 5px;
                              transition: all .5s;
                              font-weight: 300;
                              display: block;
                              line-height: 1.4;
                              margin: 0;
                              font-size: 14px;
                              border-left: 2px solid transparent;
                              i {
                                    transition: all 0.5s;
                                    color: #848484;
                                    margin-right: 5px;
                                    font-size: 14px;
                              }
                              &:hover {
                                    background-color: #f3f3f3;
                                    color: map-get($colors, orange);
                                    border-left: 2px solid #cacaca;
                                    i {
                                          color: map-get($colors, orange);
                                    }
                              }
                        }
                        &.active {
                              a {
                                    //background-color: #e4e4e4;
                                    border-left: 2px solid map-get($colors, orange);
                                    background-color: #f9f9f9;
                                    color: #656565;
                                    i {
                                          color: map-get($colors, orange);
                                    }
                              }
                        }
                  }
            }
            &.passagers {
                  li {
                        margin: 3px 0;
                        a {
                              color: #656565;
                              text-transform: uppercase;
                              border: 1px solid #e4e4e4;
                              background-color: #f9f9f9;
                              padding: 0 10px;
                              transition: all 0.5s;
                              font-weight: 500;
                              i {
                                    transition: all 0.5s;
                                    color: #848484;
                                    margin-right: 5px;
                                    font-size: 14px;
                              }
                              &:hover {
                                    background-color: #e4e4e4;
                                    i {
                                          color: map-get($colors, orange);
                                    }
                              }
                        }
                        &.active {
                              a {
                                    background-color: #e4e4e4;
                                    i {
                                          color: map-get($colors, orange);
                                    }
                              }
                        }
                  }
            }
      }
}

.fa-exclamation-triangle.text-warning {
      color: #f04f00;
}

// Swipper - Testimonio
// --------------------------------------------------
.swiper-container {
      width: 80%;
      height: 250px;
      margin: 0 auto;
}

.swiper-slide {
      text-align: center;
      font-size: $fontSize_biggest;
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      background: map-get($colors, lightgrey);
      .box-text {
            padding: 0 95px;
            position: relative;
            &:before {
                  content: "\f10d";
                  font-family: FontAwesome;
                  color: map-get($colors, orange);
                  font-size: $fontSize_title_biggest;
                  position: absolute;
                  top: 45px;
                  left: 50px;
            }
            &:after {
                  content: "\f10e";
                  font-family: FontAwesome;
                  color: map-get($colors, orange);
                  font-size: $fontSize_title_biggest;
                  position: absolute;
                  bottom: 25px;
                  right: 50px;
            }
            p {
                  font-size: $fontSize_normal;
            }
      }
}

// Google Map
// --------------------------------------------------
.box-map {
      border: 1px solid map-get($colors, grey);
      .map {
            height: 320px;
            background-color: map-get($colors, lightgrey);
      }
      .content {
            padding: 25px 0;
            &.smaller {
                  padding: 36px 0;
            }
            .fa.back-circle {
                  background-color: #eee;
                  color: #f08300;
                  font-size: 32px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  text-align: center;
                  margin-right: 25px;
                  padding: 5px;
                  margin-left: 35px;
            }
            .address {
                  margin-right: auto;
                  span {
                        display: block;
                  }
            }
      }
}

// Contact
// --------------------------------------------------
.contact-label {
      margin: 25px 0;
      padding: 30px 10px;
      text-align: center;
      .fa {
            color: map-get($colors, orange);
      }
      div {
            display: inline-block;
            .b {
                  margin-left: 5px;
            }
            &.phones {
                  padding-left: 16px;
                  //margin-left: 10px;
                  position: relative;
                  &:before {
                        content: "\f095";
                        font-family: FontAwesome;
                        position: absolute;
                        top: -3px;
                        left: 0;
                        color: map-get($colors, orange);
                        font-size: 20px;
                  }
            }
            &.email {
                  padding-left: 25px;
                  margin-left: 10px;
                  position: relative;
                  &:before {
                        content: "\f0e0";
                        font-family: FontAwesome;
                        position: absolute;
                        top: -1px;
                        left: 0;
                        color: map-get($colors, orange);
                        font-size: 18px;
                  }
            }
      }
}

.contact-form {
      background-image: url('../img/light_grey.png');
      margin: 0;
      padding: 40px 0 60px;
      textarea,
      button {
            width: 100%;
      }
      .form-control {
            background: map-get($colors, white);
            border-color: map-get($colors, lightgrey);
            border-radius: 0;
            padding: 22px 12px;
            &:focus {
                  border-color: map-get($colors, orange);
                  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
            }
      }
      .form-group {
            margin-bottom: 25px;
            label.error {
                  color: map-get($colors, danger);
                  font-weight: 400;
                  position: absolute;
                  font-size: 14px;
                  margin-top: 2px;
                  margin-left: 7px;
                  display: block;
            }
            label#message-error {
                  margin-top: -5px;
            }
      }
      .g-recaptcha {
            display: inline-block;
      }
      textarea {
            background: map-get($colors, white);
            border-color: map-get($colors, grey);
            padding: 8px 12px;
            height: 118px;
            font-size: 14px;
            &:focus {
                  border-color: map-get($colors, orange);
                  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
                  outline: 0 none;
            }
      }
}

// Poducts catalogue
// --------------------------------------------------
.products-catalogue {
      .result-box {
            border: 2px solid map-get($colors, grey);
            padding: 5px 5px 5px 10px;
            display: flex;
            align-items: center;
            .result {
                  margin-right: auto;
            }
            select {
                  margin-left: 5px;
                  min-width: 100px;
                  border: 1px solid map-get($colors, grey);
                  background-color: map-get($colors, lightgrey);
                  width: 180px;
            }
      }
      .box-filters {
            border: 1px solid map-get($colors, grey);
            background-color: map-get($colors, lightgrey);
            label {
                  font-size: 12px;
                  text-transform: uppercase;
                  color: map-get($colors, darkgrey);
            }
            .form-control {
                  border-radius: 0;
                  border: 1px solid map-get($colors, grey);
                  padding: 3px 12px;
                  font-size: 16px;
                  &:focus {
                        border-color: map-get($colors, orange);
                        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
                  }
            }
            .select2-selection {
                  background: map-get($colors, white);
                  border-color: #e8e8e8;
                  border-radius: 0;
                  padding: 5px 12px;
                  height: 34px;
                  &:focus {
                        border-color: map-get($colors, orange) !important;
                        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
                  }
            }
            .select2-selection__clear {
                  margin-right: 5px;
            }
            .select2-container *:focus {
                  outline: none;
            }
            .select2-container .select2-selection--single .select2-selection__rendered {
                  padding-left: 0;
            }
            .select2-selection__arrow {
                  display: none;
            }
            .select2-selection {
                  &:after {
                        content: "\f107";
                        font-size: 24px;
                        font-weight: bold;
                  }
            }
            .input-icon,
            .select2-selection {
                  position: relative;
                  &.select {
                        select {
                              padding-right: 30px;
                              -webkit-appearance: none;
                              -moz-appearance: none;
                              appearance: none;
                        }
                        &:after {
                              content: "\f107";
                              font-size: 24px;
                              font-weight: bold;
                        }
                  }
                  &.calendar {
                        input {
                              padding-right: 30px;
                        }
                        &:after {
                              content: "\f073";
                              font-size: 18px;
                              font-weight: 500;
                              top: 5px;
                        }
                  }
                  &:after {
                        font-family: FontAwesome;
                        color: map-get($colors, orange);
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0 7px;
                  }
                  &:before {
                        content: "";
                        background: map-get($colors, lightgrey);
                        border-left: 2px solid map-get($colors, grey);
                        position: absolute;
                        right: 1px;
                        top: 1px;
                        height: calc(100% - 2px);
                        width: 32px;
                  }
            }
            button {
                  font-size: 12px;
                  background-color: map-get($colors, blue);
                  color: map-get($colors, white);
                  transition: all 0.5s;
                  margin-bottom: 25px;
                  margin-top: 15px;
                  width: 100%;
                  &:hover {
                        background-color: map-get($colors, darkerblue);
                  }
            }
            .price-box {
                  display: flex;
                  align-items: center;
                  .price {
                        margin-left: auto;
                        font-size: 13px;
                        color: map-get($colors, blue);
                  }
            }
            .irs {
                  height: 35px;
                  margin-top: -15px;
            }
            .irs-line {
                  background-color: #e2e2e2;
                  .irs-line-left,
                  .irs-line-mid,
                  .irs-line-right {
                        background: none;
                  }
            }
            .irs-bar {
                  background-color: map-get($colors, darkerblue);
                  background-image: none;
            }
            .irs-slider {
                  &:before {
                        transition: all 0.5s;
                        content: '';
                        position: absolute;
                        height: 9px;
                        width: 9px;
                        top: 6.5px;
                        left: 6.4px;
                        background: #969696;
                        border-radius: 50%;
                  }
                  &:hover:before {
                        background: #15539c;
                        cursor: pointer;
                  }
            }
      }
}

// Services - index
// --------------------------------------------------
.service-index {
      text-align: center;
      margin: 20px 0;
      .image-box {
            width: 180px;
            display: inline-block;
            .big-circle {
                  width: 180px;
                  background: #f1f2f4;
                  border-radius: 50%;
                  height: 180px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .smaller-cicle {
                        transition: all 0.5s;
                        width: 150px;
                        background-color: transparent;
                        border-radius: 50%;
                        height: 150px;
                        display: table;
                        transition: all 0.5s;
                        .service-img {
                              display: table-cell;
                              vertical-align: middle;
                              .img-icon {
                                    fill: map-get($colors, blue);
                              }
                        }
                  }
                  &:hover {
                        .smaller-cicle {
                              background-color: rgba(255, 255, 255, 0.8);
                        }
                  }
            }
      }
      .text-box {
            margin: 10px auto;
            max-width: 200px;
            height: 55px;
            .text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  line-height: 30px;
                  max-height: 55px;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  color: map-get($colors, blue);
                  font-size: 24px;
                  text-transform: uppercase;
                  margin: 0 auto;
                  text-align: center;
            }
      }
}

//FLipper box
$flip_height: 280px;
$flip_width: 280px;
.flip-container {
      perspective: 1000px;
      width: $flip_width;
      height: $flip_height;
      max-width: 100%;
      margin: 15px auto;
      &:hover .flipper,
      &.hover .flipper {
            transform: rotateY(180deg);
      }
      &:before {
            opacity: 0;
            transition: all .5s;
            position: absolute;
            z-index: -1;
            content: '';
            top: calc(100% - 2px);
            left: 5%;
            height: 10px;
            width: 80%;
            background: radial-gradient(ellipse at center, rgba(0, 0, 0, .35) 0, transparent 80%);
      }
      &:hover:before,
      &.hover:before {
            opacity: 1;
      }
      .front,
      .back {
            width: $flip_width;
            height: $flip_height;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
      }
      .flipper {
            transition: 0.5s;
            transform-style: preserve-3d;
            position: relative;
      }
      .front {
            background-color: map-get($colors, white);
            z-index: 2;
            transform: rotateY(0deg);
            color: #656565;
            .service-img {
                  height: 55%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  .img-icon {
                        fill: map-get($colors, blue);
                  }
            }
            .text-box {
                  margin: 10px auto;
                  max-width: 200px;
                  height: 55px;
                  .text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 30px;
                        max-height: 55px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-size: 24px;
                        text-transform: uppercase;
                        margin: 0 auto;
                        text-align: center;
                        font-weight: 800;
                  }
            }
      }
      .back {
            background-color: map-get($colors, blue);
            color: map-get($colors, white);
            transform: rotateY(180deg);
            color: map-get($colors, white);
            .service-img {
                  height: 47%;
                  .img-icon {
                        fill: map-get($colors, white);
                  }
                  .title {
                        text-transform: uppercase;
                        font-weight: 800;
                        margin-top: -25px;
                        max-width: 200px;
                        margin-left: auto;
                        margin-right: auto;
                  }
            }
            .text-box {
                  margin: 15px auto;
                  max-width: 85%;
                  .text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 19px;
                        max-height: 92px;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        margin: 0 auto;
                        text-align: center;
                  }
            }
      }
}

// Product details
// --------------------------------------------------
.product-details {
      display: flex;
      .main-title {
            position: relative;
            width: calc(100% - 200px);
            .title-details {
                  margin-bottom: 5px;
                  span {
                        display: block;
                        font-size: $fontSize_bigger;
                        &.title {
                              font-size: $fontSize_title_big;
                              font-weight: 600;
                        }
                        i.color {
                              &-green {
                                    color: map-get($colors, green);
                              }
                              &-orange {
                                    color: #e08212;
                              }
                              &-grey {
                                    color: #888888;
                              }
                              &-red {
                                    color: #e25050;
                              }
                        }
                  }
            }
      }
      .payment-detail {
            width: 250px;
            padding-left: 9px;
            .title-details {
                  margin-bottom: 5px;
                  span {
                        display: block;
                        b {
                              font-weight: 500;
                        }
                        sup {
                              font-weight: 600;
                        }
                        &.title {
                              font-size: 18px;
                              font-weight: 600;
                              &.orange {
                                    color: map-get($colors, orange);
                              }
                              b {
                                    font-size: 24px;
                                    font-weight: 600;
                              }
                        }
                        &.text-smaller {
                              font-size: 14px;
                              b {
                                    font-size: 16px;
                              }
                        }
                  }
            }
      }
      &.price-details {
            display: block;
            .label-price {
                  text-transform: uppercase;
                  font-weight: 800;
            }
            .type-price {
                  font-size: 20px;
                  padding-right: 20px;
                  span {
                        font-size: 18px;
                  }
                  &.bigger {
                        font-weight: 800;
                        font-size: 24px;
                        span {
                              font-size: 20px;
                        }
                  }
            }
      }
      .btn-back {
            padding-top: 10px;
            padding-right: 10px;
      }
}

.box-outline {
      border: 1px solid map-get($colors, grey);
      padding: 5px;
      display: inline-block;
}

.social-share {
      width: calc(100% - 250px);

      .btn-circle {
            height: 50px;
            width: 50px;
            padding: 13px 0;
            &:hover {
                  color: map-get($colors, orange);
            }
      }
      ul {
            margin: 0;
            display: inherit;
      }
}

.product {
      &.info-label {
            width: 242px;
            margin-left: 9px;
            font-size: 12px;
            padding: 14px 5px;
            height: 62px;
      }
      &.pay-detail {
            font-weight: 600;
      }
      &.inputs-dates {
            display: flex;
            select {
                  width: 120px;
                  border-radius: 0;
                  padding: 0 6px;
                  height: 28px;
                  font-size: 16px;
            }
            p {
                  width: 120px;
            }
            .input-group {
                  width: 150px;
                  margin-left: 10px;
                  input {
                        border-radius: 0;
                        height: 28px;
                  }
            }
            i.fa-info-circle {
                  margin-top: 7px;
                  margin-left: 7px;
            }
      }
}

.text-info.smaller-text {
      font-size: 12px;
      margin-top: 3px;
}

// Include services
// --------------------------------------------------
.include-services {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0 4px;
      min-height: 60px;
      .status-success {
            font-size: 16px;
            font-weight: 600;
            .fa {
                  color: map-get($colors, green);
            }
      }
      .title {
            margin-left: 25px;
            &.space-left {
                  margin-left: 70px;
            }
      }
      .price {
            margin-left: auto;
      }
      .normal-text {
            font-weight: 300;
            font-size: 16px;
            margin-left: auto;
      }
}

// Payment details
// --------------------------------------------------
.payment-details-label {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      //padding: 10px 0 4px;
      min-height: 60px;
      .status-success {
            font-size: 16px;
            font-weight: 600;
            .fa {
                  color: map-get($colors, green);
            }
      }
      .title {
            margin-left: 25px;
            padding: 10px 0 4px;
            &.space-left {
                  margin-left: 70px;
            }
      }
      .price {
            margin-left: auto;
      }
      .normal-text {
            font-weight: 300;
            font-size: 16px;
            margin-left: auto;
      }
      .btn-full.default.smaller {
            padding: 12px 15px 6px 15px;
      }
}

.rent-details {
      background-color: map-get($colors, lightgrey);
      padding: 10px 5px;
      .main-info {
            .title-size {
                  font-size: 24px;
            }
            .sub-title-size {
                  font-size: 20px;
            }
            img {
                  width: 100%;
                  max-width: 250px;
            }
      }
      .data-info {
            padding: 0 5px;
            .title-size {
                  font-size: 24px;
            }
            .grey-label {
                  background-color: map-get($colors, grey);
                  padding: 5px 10px;
                  margin: 5px 0;
            }
            i {
                  margin-right: 5px;
                  margin-left: 10px;
            }
      }
      .detail-info {
            margin-top: 10px;
            .row-detail {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  .title {
                        text-transform: uppercase;
                        //width: 100px;
                        padding-right: 25px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                  }
                  .price {
                        margin-left: auto;
                  }
            }
      }
      hr {
            border-color: #d6d6d6;
      }
}

.payment-form {
      .form-control {
            background: map-get($colors, white);
            border-color: map-get($colors, grey);
            border-radius: 0;
            padding: 5px 12px;
            font-size: 16px;
            &:focus {
                  border-color: map-get($colors, orange);
                  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
            }
      }
      .form-group {
            margin-bottom: 30px;
            label.error {
                  color: map-get($colors, danger);
                  font-weight: 400;
                  position: absolute;
                  font-size: 14px;
                  margin-top: 2px;
                  margin-left: 7px;
                  display: block;
            }
            .input-group {
                  label.error {
                        bottom: -27px;
                  }
            }
            label#message-error {
                  margin-top: -5px;
            }
      }
      .input-group {
            .input-group-addon {
                  border-radius: 0;
                  //background: map-get($colors, white);
                  border-color: map-get($colors, grey);
            }
      }
}

.payment-option {
      border-bottom: 2px solid map-get($colors, grey);
      padding: 10px 0 10px 10px;
      .radio {
            label {
                  display: flex;
                  align-items: center;
                  input {
                        height: 20px;
                        width: 20px;
                        margin-top: 0px;
                  }
            }
            .option-label {
                  .info-text {
                        margin-left: 15px;
                        .title {
                              font-size: 24px;
                        }
                        .subtitle {
                              font-size: 14px;
                        }
                  }
            }
            .option-month {
                  .title {
                        font-size: 40px;
                        padding-top: 6px;
                        margin-left: 15px;
                        font-weight: 500;
                  }
                  .subtitle {
                        font-size: 16px;
                        white-space: pre-line;
                        text-align: center;
                        margin-left: 15px;
                  }
            }
      }
      .payment-prices {
            text-align: right;
            .content {
                  text-align: center;
                  .price {
                        padding-top: 8px;
                        font-weight: 600;
                        .amount {
                              font-size: 24px;
                        }
                        .abrev {
                              font-size: 18px;
                        }
                  }
                  .img-cards {
                        img {
                              margin: 0 5px;
                              height: 20px;
                        }
                  }
            }
      }
      .pay-data {
            .credit-card {
                  margin-top: 10px;
                  .card-type {
                        position: absolute;
                        top: 0;
                        right: 20px;
                        margin-top: 6px;
                        color: #999999;
                  }
            }
            .btn-pay {
                  margin: 5px 0;
                  text-align: right;
                  display: block;
            }
      }
      &.active {
            background-color: map-get( $colors, lightgrey);
      }
}

// loopview with checkbox and svg
// --------------------------------------------------
.loopview {
      background-color: #f7f7f7;
      margin: 20px auto;
      width: $flip_width;
      height: $flip_height;
      transition: all 0.5s;
      .top {
            height: 220px;
            transition: all 0.5s;
            .service-img {
                  height: 130px;
                  transition: all 0.5s;
                  .img-icon {
                        fill: map-get($colors, blue);
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                  }
            }
            .text-box {
                  margin: 10px auto;
                  max-width: 200px;
                  height: 55px;
                  color: #676767;
                  transition: all 0.5s;
                  .text {
                        transition: font-size 1s;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 30px;
                        max-height: 55px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-size: 24px;
                        text-transform: uppercase;
                        margin: 0 auto;
                        text-align: center;
                        font-weight: 800;
                  }
                  .text-infomation {
                        transform-origin: top;
                        transition: transform 0.5s ease;
                        transform: scaleY(0);
                        overflow: hidden;
                        font-size: 12px;
                        span {
                              color: red;
                        }
                  }
            }
      }
      .footer {
            //opacity: 1;
            transition: all 0.5s;
            padding: 5px;
            display: flex;
            align-items: center;
            background-color: #f3f3f3;
            max-width: $flip_width;
            .view,
            .add {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 50px;
                  width: 50px;
                  cursor: pointer;
                  transition: all 0.3s;
                  i {
                        display: table-cell;
                        font-size: 24px;
                        vertical-align: middle;
                        transition: all 0.5s;
                  }
            }
            .info {
                  margin: 0 auto;
                  font-size: 20px;
                  position: relative;
                  transition: all 0.5s;
                  span {
                        display: block;
                        &.lab-info {
                              color: map-get($colors, orange);
                              font-size: 12px;
                              transition: all 0.5s;
                        }
                  }
            }
            .view {
                  background-color: #ececec;
                  &:hover {
                        background-color: map-get($colors, blue);
                        color: map-get($colors, white);
                  }
            }
            .add {
                  background-color: map-get($colors, orange);
                  color: map-get($colors, white);
                  margin-bottom: 0;
                  .fa-minus {
                        display: none;
                  }
            }
      }
      &.view-details {
            .top {
                  .service-img {
                        height: 85px;
                  }
                  .text-box {
                        .text {
                              transition: font-size 0.3s;
                              font-size: 18px;
                        }
                        .text-infomation {
                              transform: scaleY(1);
                        }
                  }
            }
      }
      &.view-selected {
            background-color: map-get($colors, blue);
            .top {
                  color: map-get($colors, white);
                  .service-img {
                        .img-icon {
                              fill: map-get($colors, white);
                        }
                  }
                  .text-box {
                        color: map-get($colors, white);
                  }
            }
            .footer {
                  background-color: rgba(243, 243, 243, 0.2);
                  .info,
                  .info .lab-info {
                        color: map-get($colors, white);
                  }
                  .view,
                  .add {
                        background-color: rgba(206, 206, 206, 0.7);
                        i {
                              color: map-get($colors, blue);
                        }
                        &:hover {
                              background-color: rgba(206, 206, 206, 1);
                        }
                  }
            }
            .fa-plus {
                  display: none !important;
            }
            .fa-minus {
                  display: initial !important;
            }
      }
}

// Login view
// --------------------------------------------------
.login-form,
.register-form {
      padding: 10px 20px;
      label {
            text-transform: uppercase;
      }
      .form-control {
            background: map-get($colors, white);
            border-color: map-get($colors, grey);
            border-radius: 0;
            padding: 5px 12px;
            &:focus {
                  border-color: map-get($colors, orange);
                  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 0, .6);
                  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(240, 131, 03, .6);
            }
      }
      .register-terms {
            background-color: #f1f1f1;
            label {
                  text-transform: initial;
                  margin-left: 20px;
                  margin-top: 8px;
            }
      }
}

// Date pick up
// --------------------------------------------------
.pickmeup {
      background: #f1f1f1;
      border-radius: 0;
      border: 1px solid #c3c3c3;
      .pmu-instance {
            .pmu-day-of-week {
                  background-color: #e0e0e0;
            }
            >nav .pmu-button,
            .pmu-days .pmu-button {
                  color: map-get($colors, orange);
            }
            .pmu-not-in-month {
                  color: #3c3c3c !important;
            }
            .pmu-disabled {
                  color: #b3b3b3!important;
            }
            .pmu-day-of-week {
                  color: map-get($colors, blue);
            }
            //.pmu-today,
            .pmu-selected {
                  color: #fff !important;
                  &:hover {
                        color: #0c77d4 !important;
                  }
            }
            .pmu-button:hover {
                  color: #0c77d4;
            }
            .pmu-sunday,
            .pmu-saturday {
                  opacity: 0.7;
            }
      }
}

// Paginator
// --------------------------------------------------
.pagination.custom {
      &>li {
            &:first-of-type,
            &:last-of-type {
                  font-weight: 600;
                  a,
                  span {
                        border-radius: 0;
                        height: auto;
                        width: auto;
                        padding: 6px 12px;
                        line-height: inherit;
                  }
                  i {
                        font-size: 20px;
                        font-weight: 600;
                        &:before {
                              margin-bottom: -4px;
                              display: table;
                        }
                  }
                  &:not(.disabled) {
                        a,
                        span {
                              &:hover {
                                    background-color: #fff;
                                    color: #222;
                                    i {
                                          color: map-get($colors, orange);
                                    }
                              }
                        }
                  }
            }
            a,
            span {
                  color: #222;
                  border-radius: 50%;
                  margin: 0 2px;
                  height: 30px;
                  width: 30px;
                  padding: 0;
                  text-align: center;
                  line-height: 35px;
                  border: 0;
                  &:hover {
                        color: #fff;
                        background-color: map-get($colors, orange);
                  }
            }
            &.active {
                  a,
                  span {
                        color: #fff;
                        background-color: #e88309;
                        &:hover {
                              color: #fff;
                              background-color: #e88309;
                        }
                  }
            }
      }
      &>.disabled> {
            a {
                  color: #9e9e9e;
                  cursor: not-allowed;
                  background-color: #fff;
                  border-color: #ddd;
                  &:focus,
                  &:hover {
                        color: #9e9e9e;
                        cursor: not-allowed;
                        background-color: #fff;
                        border-color: #ddd;
                  }
            }
            span {
                  color: #9e9e9e;
                  cursor: not-allowed;
                  background-color: #fff;
                  border-color: #ddd;
                  &:focus,
                  &:hover {
                        color: #9e9e9e;
                        cursor: not-allowed;
                        background-color: #fff;
                        border-color: #ddd;
                  }
            }
      }
}

// Paginator
// --------------------------------------------------
.navbar-custom {
      .dropbtn {
            border: none;
            cursor: pointer;
            i.fa-user-circle {
                  position: inherit;
                  transition: all 0.5s;
            }
            &:hover,
            &.active {
                  i {
                        color: #fff;
                  }
            }
      }
      .dropdown {
            position: relative;
            display: inline-block;
      }
      .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            width: calc(100% - 5px);
            margin-left: 5px;
      }
      .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            text-align: left;
      }
      .dropdown-content a:hover {
            background-color: #f1f1f1
      }
      .dropdown:hover .dropdown-content {
            display: block;
      }
      .dropdown:hover .dropbtn {
            color: #f7f7f7;
            background: #055499;
            i {
                  color: #f7f7f7;
            }
      }
}

@media screen and (max-width: 768px) {
    .panel-derecho{
        display: none;
    }
}
